import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Navbar from "./components/navbar";
import LandingPage from "./components/landing";
import Portfolio from "./components/portfolio";
import Blog from "./components/blog";
import About from "./components/about";
import NotFound from "./components/notFound";
import "./App.css";

function App() {
  return (
    <React.Fragment>
      <div className="custom-container">
      <p>currently under construction</p>
      </div>
    </React.Fragment>
  );
}

export default App;
