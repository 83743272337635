const blogPosts = [
  {
    title: "Blog post 1",
    date: "Janu23 2ary 1st 2000",
    content: `Straight off the bat, a big thank you to my man Alexandre Moreira for making this <a class="blog-links" href="https://alexandremoreira.se/home" target="_blank">website</a>. Check out his other work. As a matter of fact, while you’re at it, check us out performing a cover of Radiohead’s <a class="blog-links" href="https://www.youtube.com/watch?v=Kct7fcPGOK0" target="_blank">Give Up The Ghost</a> or Storm Corrosion’s <a class="blog-links" href="https://www.youtube.com/watch?v=5n8xxOKkAKo" target="_blank">Ljudet Innan</a> in his old apartment. Next to being a programmer, he has made waves as a studio engineer and producer. We both did not expect to live in Sweden for this long, yet here we are. We both have to hustle to get by. But what luck we have to know such wonderful people here, especially in these uncertain, viral times.`
  }
];

export function getPosts() {
  return blogPosts;
}
