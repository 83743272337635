const portfolio = [
  {
    title: "Ik laat me steriliseren om het klimaat te redden",
    date: "11 July 2019",
    publisher: "Vice NL",
    type: "Magazine",
    publisherURL: "https://www.vice.com/nl",
    articleURL:
      "https://www.vice.com/nl/article/vb9agj/steriliseren-voor-het-klimaat-gurgin-bakircioglu",
    content: {
      p1: `"Natuurlijk kan ik ook een condoom gebruiken, maar dit geeft echt zekerheid. En geen kinderen krijgen is het beste dat je voor de aarde kan doen."`,
      p2: `Gurgîn Bakircioglu is eenasdf a ss 35-jarige Zweeds-Koerdische podcastmaker en klimaatactivist. In mei veroorzaakte hij nogal wat opschudding in Zweden met zijn opiniestuk in de krant Expressen, waarin hij stelde dat geen kinderen krijgen de beste manier is om je ecologische voetafdruk te verkleinen. Gurgîn wil zelf dan ook niet nog meer mensen op de wereld zetten. Daarom laat hij zich binnenkort steriliseren.`
    }
  },
  {
    title: "Meet the American trans activist seeking asylum in Sweden",
    date: "24 September 2018",
    publisher: "The Local Sweden",
    type: "Newspaper",
    publisherURL: "https://www.thelocal.se/",
    articleURL:
      "https://www.thelocal.se/20180924/meet-the-american-trans-activist-seeking-asylum-in-sweden",
    content: {
      p1: `Askini's story is one of great hardships, yet she radiates positivity. Passionate and enthusiastic, the Seattle native talks frankly about her career, her private life, and her hopes for the future.`,
      p2: `"I was adopted, and I grew up in foster care. It was a rough time. I went through homelessness, and I transitioned from male to female as a teenager. Since the late '90s, I have been doing LGBT activism, but more specifically, trans activism. In 2006, I met a Swede and fell in love. From that time, I started moving back and forth to Sweden, until our relationship ended in 2015," she says.`
    }
  }
];

export function getPortfolio() {
  return portfolio;
}
